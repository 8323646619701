import { logError } from '../../../../lib/utils/logger'

export default (() => {
  class FooterController {
    constructor({ selector }) {
      this.footer = document.querySelector(selector)
      if (!this.footer) return

      if (this.footer.classList.contains('shopping')) {
        this.initNCIDForwarder(selector)
      }
    }

    initNCIDForwarder(selector) {
      const { ncidForwarder } = window.NEXUtils || {}
      if (!ncidForwarder) {
        logError('Footer', 'NCID Forwarder not found')
        return
      }

      ncidForwarder.addTrackedLinksSelector(`${selector} a`)
    }
  }

  return FooterController
})()
